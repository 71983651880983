import * as React from 'react';
import { contentFetch } from '../../lib/content-fetcher';
import { COLORS } from '../../utils/theme';
import { DataContainer, DataSection, DataBox, DataNumber, DataLabel } from './styles';

const NetworkData = () => {
  const [users, setUsers] = React.useState([]);
  const [countries, setCountries] = React.useState(null);
  const [typeOfMembers, setTypeOfMembers] = React.useState([]);
  const [independentUsers, setIndependentUsers] = React.useState(null);
  const [network, setNetwork] = React.useState(null);

  React.useEffect(() => {
    contentFetch({
      pathname: '/users?member=true', // TODO add new backend api that uses &_limit=-1 to get all cities and filters
      setState: setUsers,
    });
    contentFetch({
      pathname: '/network',
      setState: setNetwork,
    });
    contentFetch({
      pathname: '/user-survey-type-of-members',
      setState: setTypeOfMembers,
    });
  }, []);

  React.useEffect(() => {
    const countriesAmount = users.reduce((acc, user) => {
      user.countries &&
        user.countries.forEach((country) => {
          acc.add(country.name);
        });

      return acc;
    }, new Set()).size;

    setCountries(countriesAmount);
  }, [users]);

  React.useEffect(() => {
    if (typeOfMembers.length) {
      const indenpendentId = typeOfMembers.find(
        (typeOfMember) => typeOfMember.label === 'Individual',
      )._id;
      const organizationId = typeOfMembers.find(
        (typeOfMember) => typeOfMember.label === 'Organization',
      )._id;
      contentFetch({
        pathname: `/users/count?typeOfMember=${indenpendentId}&typeOfMember=${organizationId}&member=true`,
        setState: setIndependentUsers,
      });
    }
  }, [typeOfMembers]);

  if (!typeOfMembers.length) {
    return null;
  }

  return (
    <DataSection>
      <DataContainer>
        <DataBox>
          <DataNumber>{independentUsers}</DataNumber>
          <DataLabel>Members of the Network</DataLabel>
        </DataBox>
        <DataBox>
          <DataNumber color={COLORS.BLUE}>{countries}</DataNumber>
          <DataLabel color={COLORS.BLUE}>Countries</DataLabel>
        </DataBox>
        <DataBox>
          <DataNumber>{network?.monthlyVisits}</DataNumber>
          <DataLabel>Monthly Visits</DataLabel>
        </DataBox>
      </DataContainer>
    </DataSection>
  );
};

export default NetworkData;
