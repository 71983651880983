import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/theme';

export const Iframe = styled.iframe`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-height: 900px;
  height: 90vh;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    height: 90vh;
  }
`;
