import React from 'react';
import { useInterpret } from '@xstate/react';

import { URL_CREATIVE_NET_IFRAME } from '../../utils/urls';

import Iframe from '../../components/Iframe';
import NetworkProfile from './NetworkProfile';

import { profileMachine } from './NetworkProfile/profileMachine';

const Network = () => {
  const networkProfileService = useInterpret(profileMachine);

  return (
    <>
      <Iframe src={URL_CREATIVE_NET_IFRAME} />
      
      <NetworkProfile service={networkProfileService}></NetworkProfile>
    </>
  );
};

export default Network;
