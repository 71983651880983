import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../utils/theme';

export const DataSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline: 50px;
  padding-block: 80px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 30px 20px 0;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
  }
`;

export const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-right: 1px solid;

  &:last-child {
    border: none;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    border: none;
    border-top: 1px solid;
    padding: 30px 0;

    &:last-child {
      border-top: 1px solid;
    }
  }
`;

export const DataNumber = styled.span`
  font-size: 72px;
  color: ${(props) => props.color || COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.19px;
  }
`;

export const DataLabel = styled(DataNumber)`
  font-size: 16px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.34px;
  }
`;
