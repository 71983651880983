import * as React from 'react';
import loadable from '@loadable/component';

import { COLORS } from '../utils/theme';
import SEO from '../components/SEO';
import Background from '../components/Background';
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import NewMembers from '../components/NewMembers';
import NetworkData from '../components/NetworkData';
import NetworkDisclaimer from '../components/NetworkDisclaimer';

import Network from '../components/Network';

import useScrollPosition from '@react-hook/window-scroll';

import { Logo, MenuButton, SectionContainer } from '../components/HomeHeader/styles';

import { PAGES_TYPES } from '../utils/constants';
import { contentFetch } from '../lib/content-fetcher';

const Footer = loadable(() => import('../components/Footer'));

const NetworkMapPage = () => {
  const [members, setMembers] = React.useState([]);
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;
  React.useEffect(() => {
    contentFetch({
      pathname: `/users?member=true&_sort=createdAt:DESC&_limit=5`,
      setState: setMembers,
    });
  }, []);
  const [menuIsShown, showMenu] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(false);

  const goToLogin = () => {
    setShowLogin(true);
    setTimeout(() => setShowLogin(false), 500);
  };

  const goToRegister = () => {
    setShowRegister(true);
    setTimeout(() => setShowRegister(false), 500);
  };

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Network"></SEO>
      <Header
        menuIsShown={menuIsShown}
        showMenu={showMenu}
        showLogin={showLogin}
        showRegister={showRegister}
      />
      <SectionContainer>
        <Logo visible={!hide} />
        <MenuButton visible={!hide} menuIsShown={menuIsShown} />
        <HeaderTitle menuIsShown={menuIsShown} type={PAGES_TYPES.MAP_MATCHMAKER} />
        <Network></Network>
        <NetworkData />
        <NetworkDisclaimer />
        <NewMembers members={members} joinUs goToLogin={goToLogin} goToRegister={goToRegister} />
      </SectionContainer>
      <Footer />
    </Background>
  );
};

export default NetworkMapPage;
